import React from 'react'

import { ContactUsForm } from '@/components'
import { Select } from '@/uikit'

export default function DemoPage() {
  return (
    <div className="flex items-center justify-center w-full">
      <ContactUsForm />
    </div>
  )
}
